import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import GjTitle from '../components/GjTitle';
import { TbMessage, TbUser } from '../models';
import { Button } from '@material-ui/core';
import { GjDetailComment } from './GjDetailComment';
import { Auth } from 'aws-amplify';
import { AppApiContext } from '../App';
import { GjCreateNewMessage } from './GjCreateNewMessage';

export default function GjDashboardSummary(props: {
  tbMessages: TbMessage[];
  tbUser: TbUser | null;
}) {
  const appApi = useContext(AppApiContext);
  let scoreFrom = 0;
  let scoreTo = 0;
  let scoreFromSameMonth = 0;
  let scoreToSameMonth = 0;
  let blSendMessage = false;
  const userId = props.tbUser?.id;
  for (const row of props.tbMessages) {
    if (row.userIdFrom === userId) scoreFrom += row.scoreFrom;
    if (row.userIdTo === userId) scoreTo += row.scoreTo;
      if (row.userIdFrom === userId
          && new Date().getFullYear() === new Date(row.datetime * 1000).getFullYear()
          && new Date().getMonth() === new Date(row.datetime * 1000).getMonth()) {
            scoreFromSameMonth += row.scoreFrom;
            blSendMessage = true;
    }
      if (row.userIdTo === userId
          && new Date().getFullYear() === new Date(row.datetime * 1000).getFullYear()
          && new Date().getMonth() === new Date(row.datetime * 1000).getMonth()) {
            scoreToSameMonth += row.scoreTo;
      }
  }

  async function handleClickCreateNewMessage() {

    // 新規メッセージ作成画面に遷移。
    appApi.changePage(GjCreateNewMessage)
  }

  return (
    <React.Fragment>
      <GjTitle>{props.tbUser?.name}</GjTitle>
      <Typography component="p" variant="h6">
        受信の点数：{scoreTo}（今月の点数：{scoreToSameMonth}）
      </Typography>
      <Typography component="p" variant="h6">
        送信の点数：{scoreFrom}（今月の点数：{scoreFromSameMonth}）
      </Typography>
      <Typography component="p" variant="h6">
        合計点数：{scoreTo + scoreFrom}（今月の点数：{scoreToSameMonth + scoreFromSameMonth}）
      </Typography>
      {!blSendMessage ?
        <Typography component="p" variant="h6">
          今月もグッジョブカードを送ってみましょう！
          <Button variant="contained" color="primary" style={{ width: '250px' }} onClick={() => handleClickCreateNewMessage()}>
            グッジョブカードを作成する
          </Button>
        </Typography>
      : ''}
    </React.Fragment>
  );
}
