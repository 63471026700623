import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GjTitle from '../components/GjTitle';
import { Auth } from '@aws-amplify/auth';

import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { TbMessage, TbUser } from '../models';
import { GjBackToDashboard } from '../components/GjBackToDashboard';
import { categoryName, shubetsuName } from '../gjFunctions';

import { styled } from '@material-ui/core/styles';

type tbMessageWithUser = TbMessage & { userFrom?: TbUser };

// Kayawake Start Add 行の背景色設定
const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(4n-1)': {
    backgroundColor: theme.palette.action.hover,
  }
}));

const StyledTableRow2 = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(4n)': {
    backgroundColor: theme.palette.action.hover,
  }
}));
// Kayawake End Add

/**
 * 受信履歴画面。
 * @returns 当コンポーネント。
 */
export function GjReceivedData() {
  const [tbMessageWithUsers, setTbMessageWithUsers] = useState<
    tbMessageWithUser[]
  >([]);

  // データ取得。
  async function getData() {
    const authUser = await Auth.currentAuthenticatedUser();
    const tbUser = await DataStore.query(TbUser, authUser.username);
    if (tbUser) {
      // データを取得。
      const tbMessages = await DataStore.query(
        TbMessage,
        (c) => c.userIdTo('eq', tbUser.id),
        {
          sort: (s) => s.datetime(SortDirection.DESCENDING),
        }
      );

      // ユーザー名を取得する。Promise.allを使うことで並列にアクセス。
      const tbMessageWithUsers = await Promise.all(
        tbMessages.map(async (tbMessage) => {
          const tbUser = await DataStore.query(TbUser, tbMessage.userIdFrom);
          return { ...tbMessage, userFrom: tbUser };
        })
      );

      // 取得したデータを反映。
      setTbMessageWithUsers(tbMessageWithUsers);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <React.Fragment>
      <GjTitle>貰ったグッジョブカード</GjTitle>
      <Table size="small">
        <TableHead>
          <StyledTableRow1>
            <TableCell>日時</TableCell>
            <TableCell>カテゴリ</TableCell>
            <TableCell>種別</TableCell>
            <TableCell align="right">点数</TableCell>
          </StyledTableRow1>
          <StyledTableRow2>
            <TableCell>名前</TableCell>
            <TableCell colSpan={3}>メッセージ</TableCell>
          </StyledTableRow2>
        </TableHead>
        <TableBody>
          {tbMessageWithUsers.map((row) => (
            <React.Fragment>
              <StyledTableRow1>
                <TableCell>
                  {new Date(row.datetime * 1000).toLocaleString()}
                </TableCell>
                <TableCell>{categoryName(row.category)}</TableCell>
                <TableCell>{shubetsuName(row.shubetsu)}</TableCell>
                <TableCell align="right">{row.scoreTo}</TableCell>
              </StyledTableRow1>
              <StyledTableRow2>
                <TableCell>{row.userFrom?.name}</TableCell>
                <TableCell colSpan={3}>{row.message}</TableCell>
              </StyledTableRow2>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <GjBackToDashboard />
    </React.Fragment>
  );
}

GjReceivedData.displayName = '受信履歴';
