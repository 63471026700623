import { Auth } from '@aws-amplify/auth';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import {
    Box,
    Button,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { Predicates } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useContext, useState } from 'react';
import { AppApiContext } from '../App';
import { GjBackToDashboard } from '../components/GjBackToDashboard';
import GjTitle from '../components/GjTitle';
import { TbUser, TbAppComment } from '../models';
import { GjCreateComment } from './GjCreateComment';
import { GjDetailComment } from './GjDetailComment';

type tbAppCommentEdit = TbAppComment & { userFrom?: TbUser } & { userReFrom?: TbUser } & { comment: String } & { reComment: String } & { blAllComment: Boolean } & { blCompleted: Boolean } & { dateStr?: string };

const useStyles = makeStyles((theme) => ({
    growDiv: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

export function GjComment() {
    const appApi = useContext(AppApiContext);

    const maxCommentCnt = 5 // 1ページの最大表示数

    // コメント編集後リスト
    const [tbAppCommentEdit, setTbAppCommentEdit] = useState<
        tbAppCommentEdit[]
    >([]);

    // コメント表示用リスト
    const [tbAppCommentDisplay, setTbAppCommentDisplay] = useState<
        tbAppCommentEdit[]
    >([]);

    // ページネーション用リスト
    const [peganation, setPeganation] = useState<
        number[]
    >([]);

    // ページ設定
    async function setPage(commentLength: number) {

        let pageList: React.SetStateAction<number[]> = []
        setPeganation(pageList)
        const maxPage = Math.floor(commentLength / maxCommentCnt)
        const fraction = commentLength % maxCommentCnt
        if (maxPage == 0) {
            pageList.push(1)
        } else {
            for (var i = 1; i <= maxPage; i++) {
                pageList.push(i)
                if (maxPage == i && fraction != 0) {
                    pageList.push(i + 1)
                }
            }
        }
        setPeganation(pageList)

    }

    // データ取得
    async function getData(selectKey: string) {

        // コメントデータを取得。
        const tbAppComments = await DataStore.query(
            TbAppComment, Predicates.ALL, {
            sort: (s) => s.datetime(SortDirection.DESCENDING)
        });

        let spliceIdByCompList: string[] = [];

        // ソート内容に合わせて表示内容を絞る
        if (selectKey == "incomp") {
            tbAppComments.map(async (tbAppComment) => {
                if (tbAppComment.completedAt) {
                    spliceIdByCompList.push(tbAppComment.id);
                }
            })
        } else if (selectKey == "comp") {
            tbAppComments.map(async (tbAppComment) => {
                if (!tbAppComment.completedAt) {
                    spliceIdByCompList.push(tbAppComment.id);
                }
            })
        }

        if (selectKey == "incomp" || selectKey == "comp") {
            spliceIdByCompList.map(async (spliceId) => {
                const index = tbAppComments.findIndex((f) => f.id == spliceId);
                tbAppComments.splice(index, 1);
            })
        }

        // ページ設定
        setPage(tbAppComments.length);

        // コメント編集
        getDataEdit(tbAppComments);

    }

    async function getDataEdit(tbAppComments: TbAppComment[]) {

        const MAX_LENGTH = 20 //コメント表示時の文字数上限
        let subCommets = '' //上限以上をカットしたコメント
        let subReCommets = '' //上限以上をカットした返信コメント
        let allComment = false
        let completedComment = false
        let tbCommentPaging: tbAppCommentEdit[] = []

        // ユーザー名を取得する。Promise.allを使うことで並列にアクセス。
        const tbCommentEdit = await Promise.all(
            tbAppComments.map(async (tbAppComment) => {
                const tbUser = await DataStore.query(TbUser, tbAppComment.userId);
                const tbReUser = await DataStore.query(TbUser, String(tbAppComment.replyUserId));
                // 表示用コメント編集
                if (tbAppComment.commentMsg.length > MAX_LENGTH) {
                    subCommets = tbAppComment.commentMsg.substr(0, MAX_LENGTH) + '...'
                } else {
                    subCommets = tbAppComment.commentMsg
                }
                // 表示用返信コメント編集
                if (String(tbAppComment.replyMsg).length > MAX_LENGTH) {
                    subReCommets = String(tbAppComment.replyMsg).substr(0, MAX_LENGTH) + '...'
                } else {
                    subReCommets = String(tbAppComment.replyMsg)
                }
                if (String(tbAppComment.replyMsg) === "null") {
                    subReCommets = ''
                }
                // 表示日付が3桁の場合、項目名の「日付」が縦表示されるため、桁数を調整。
                let dateStr;
                if (new Date(tbAppComment.datetime * 1000).toLocaleDateString().substring(5).length == 3) {
                    dateStr = new Date(tbAppComment.datetime * 1000).toLocaleDateString().substring(5) + '　';
                } else {
                    dateStr = new Date(tbAppComment.datetime * 1000).toLocaleDateString().substring(5);
                }
                return { ...tbAppComment, userFrom: tbUser, userReFrom: tbReUser, comment: subCommets, reComment: subReCommets, blAllComment: allComment, blCompleted: completedComment, dateStr: dateStr };
            })
        );

        // 取得したデータ編集後リストに反映。
        setTbAppCommentEdit(tbCommentEdit);

        // ページ切り替え
        const initPageStr = "0"
        tbCommentPaging = tbCommentEdit;
        changePage(tbCommentPaging, initPageStr)

    }

    // ページ切り替え
    async function changePage(tbCommentPaging: tbAppCommentEdit[], pageNumStr: string) {

        let tbAppCommentsPageing: tbAppCommentEdit[] = [];

        let pageNum = Number(pageNumStr) - 1;

        // 選択したページに合わせて表示内容を絞る
        let maxCommentNum: number
        let minCommentNum: number
        if (pageNum == -1) {
            minCommentNum = 0
            maxCommentNum = maxCommentCnt - 1
        } else {
            minCommentNum = pageNum * maxCommentCnt
            maxCommentNum = (pageNum + 1) * maxCommentCnt - 1
        }


        for (var i = minCommentNum; i <= maxCommentNum; i++) {
            tbAppCommentsPageing.push(tbCommentPaging[i])
            if (i == tbCommentPaging.length - 1) {
                break;
            }
        }

        // 画面表示用リストに反映。
        setTbAppCommentDisplay(tbAppCommentsPageing);
    }

    useEffect(() => {
        getData("");
    }, []);

    async function handleClick() {
        const authUser = await Auth.currentAuthenticatedUser();
        const fromUser = await DataStore.query(TbUser, authUser.username);

        // 現ユーザーが取れない。発生しないはずだが、エラーとして抜ける。
        if (fromUser == null) return;

        // コメント作成画面に遷移。
        appApi.changePage(GjCreateComment)
    }

    async function handleClickDetail(id: string) {
        const authUser = await Auth.currentAuthenticatedUser();
        const fromUser = await DataStore.query(TbUser, authUser.username);

        // 現ユーザーが取れない。発生しないはずだが、エラーとして抜ける。
        if (fromUser == null) return;

        // コメント詳細画面に遷移。
        appApi.changePage(GjDetailComment, id)
    }

    return (
        <>
            <GjTitle>コメント一覧</GjTitle>
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box textAlign="center">
                            <Button variant="contained" color="primary" onClick={handleClick}>
                                新規コメント作成
                          </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box textAlign="left">
                            <select
                                onChange={(e) =>
                                    changePage(tbAppCommentEdit, e.target.value)
                                }>
                                {peganation.map((row) => (
                                    <option value={row}>{row} ページ目</option>
                                ))}
                            </select>
                        </Box>
                        <Box textAlign="right">
                            <select
                                onChange={(e) =>
                                    getData(e.target.value)
                                }>
                                <option value="" selected>全て表示</option>
                                <option value="incomp">未完了のみ表示</option>
                                <option value="comp">完了のみ表示</option>
                            </select>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>日付</TableCell>
                                    <TableCell>コメント</TableCell>
                                    <TableCell>詳細</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tbAppCommentDisplay.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell>
                                            {row.dateStr}
                                        </TableCell>
                                        <TableCell>{row.comment}</TableCell>
                                            <Button variant="contained" color="primary" onClick={() => handleClickDetail(row.id)}>
                                                表示
                                            </Button>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </form>
            <GjBackToDashboard />
        </>
    );
}

GjComment.displayName = 'コメント';
