import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GjTitle from '../components/GjTitle';

import { TbMessage, TbUser } from '../models';
import { GjReceivedData } from './GjReceivedData';
import { GjLink } from '../components/GjLink';
import { DataStore } from '@aws-amplify/datastore';

const useStyles = makeStyles((theme) => ({
  div: {
    marginTop: theme.spacing(3),
  },
}));

type tbMessageWithUser = TbMessage & { dateStr?: string };

/**
 * ダッシュボードの受信データ。
 * @param props.tbMessages メッセージ。
 * @param props.tbUser ユーザー。
 * @returns 当該コンポーネント。
 */
export function GjDashboardReceivedData(props: {
  tbMessages: TbMessage[];
  tbUser: TbUser | null;
}) {
  const classes = useStyles();

  const [tbMessageWithUsers, setTbMessageWithUsers] = useState<
    tbMessageWithUser[]
  >([]);

  // ユーザー名を取得するため、useEffect内で処理。
  useEffect(() => {
    // 対象となるデータを抽出。
    const tbMessages = props.tbMessages
      .filter((c) => c.userIdTo === props.tbUser?.id)
      .slice(0, 3);

    // ユーザー名を取得する。Promise.allを使うことで並列にアクセス。
    async function fetchUsers() {
      const tbMessageWithUsers = await Promise.all(
        tbMessages.map(async (tbMessage) => {
          let dateStr: string;
          // 表示日付が3桁の場合、項目名の「日付」が縦表示されるため、桁数を調整。
          if (new Date(tbMessage.datetime * 1000).toLocaleDateString().substring(5).length == 3) {
              dateStr = new Date(tbMessage.datetime * 1000).toLocaleDateString().substring(5) + '　';
          } else {
              dateStr = new Date(tbMessage.datetime * 1000).toLocaleDateString().substring(5);
          }
            return { ...tbMessage, dateStr: dateStr };
        })
      );
      // 取得したユーザー名を反映。
      setTbMessageWithUsers(tbMessageWithUsers);
    }
    fetchUsers();
  }, [props.tbMessages, props.tbUser]);

  return tbMessageWithUsers.length === 0 ? (
    <>
      <GjTitle>貰ったグッジョブカード</GjTitle>
      <div className={classes.div}>ありません。</div>
    </>
  ) : (
    <>
      <GjTitle>貰ったグッジョブカード</GjTitle>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日付</TableCell>
            <TableCell>メッセージ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tbMessageWithUsers.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.dateStr}
              </TableCell>
              <TableCell>{row.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.div}>
        <GjLink gamen={GjReceivedData}>受信履歴画面で全量を見る</GjLink>
      </div>
    </>
  );
}
