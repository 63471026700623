import { Auth } from '@aws-amplify/auth';
import { DataStore } from '@aws-amplify/datastore';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { AppApiContext } from '../App';
import { GjBackToDashboard } from '../components/GjBackToDashboard';
import GjTitle from '../components/GjTitle';
import { TbUser, TbAppComment } from '../models';
import { GjComment } from './GjComment';

const useStyles = makeStyles((theme) => ({
    growDiv: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

// 初期フォーム状態。
const initialFormState = {
    commentMsg: '',
};

export function GjCreateComment() {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formData, setFormData] = useState(initialFormState);
    // エラー状態。
    const [commentMsgErrorMessage, setCommentMsgErrorMessage] = useState('');
    const appApi = useContext(AppApiContext);

    async function handleClick() {
        const authUser = await Auth.currentAuthenticatedUser();
        const fromUser = await DataStore.query(TbUser, authUser.username);

        // 現ユーザーが取れない。発生しないはずだが、エラーとして抜ける。
        if (fromUser == null) return;

        // メッセージの必須入力チェック
        if (formData.commentMsg === '') {
            setCommentMsgErrorMessage('必須入力項目です。');
            return;
        }
        // メッセージの長さチェック（サロゲートペアを意識してます）
        if (Array.from(formData.commentMsg).length > 500) {
            setCommentMsgErrorMessage('メッセージは500字以内で入力下さい。');

            return;
        }
        setCommentMsgErrorMessage('');

        // コメント文字数計測処理
        const msgArray: string[] = formData.commentMsg.split("");
        let editCommentMsg = formData.commentMsg;
        let msgCnt: any;
        let sliStart = 0
        let sliEnd = Array.from(formData.commentMsg).length
        for (var str in msgArray) {
            msgCnt++;
            if (msgCnt % 100 == 0) {
                editCommentMsg = editCommentMsg.slice(sliStart, msgCnt) + "<br />" + editCommentMsg.slice(msgCnt, sliEnd)
            }
        }

        // データ更新（送信）
        await DataStore.save(
            new TbAppComment({
                userId: fromUser.id,
                datetime: Math.floor(Date.now() / 1000),
                commentMsg: editCommentMsg,
            })
        );

        // 送信したよダイアログ表示。
        setDialogOpen(true);
    }
  return (
    <>
      <GjTitle>新しいグッジョブカード</GjTitle>
      グッジョブカードシステムへのコメントを募集しています！！
          <Grid item xs={12}>
              <div className={classes.growDiv}>
                  <TextField
                      label="コメント"
                      name="commentMsg"
                      multiline
                      variant="outlined"
                      onChange={(e) =>
                          setFormData({ ...formData, commentMsg: e.target.value })
                      }
                      error={commentMsgErrorMessage !== ''}
                      helperText={commentMsgErrorMessage}
                      value={formData.commentMsg}
                  />
              </div>
          </Grid>
          <Grid item xs={12}>
              <Box textAlign="center">
                  <Button variant="contained" color="primary" onClick={handleClick}>
                      送信
              </Button>
              </Box>
          </Grid>
          <GjBackToDashboard />
          <Dialog open={dialogOpen}>
              <DialogTitle>グッジョブカードシステム</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      コメントを送信しました。
                      ご協力ありがとうございました！
          </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      variant="contained"
                      onClick={() => appApi.changePage(GjComment)}
                      color="primary"
                  >
                      OK
          </Button>
              </DialogActions>
          </Dialog>
    </>
  );
}

GjCreateComment.displayName = 'コメント作成';
