import React, { useContext } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CreateIcon from '@material-ui/icons/Create';
import SendIcon from '@material-ui/icons/Send';
import EmailIcon from '@material-ui/icons/Email';
import CommentIcon from '@material-ui/icons/Comment';
import { AppApiContext } from '../App';
import { GjDashboard } from '../pages/GjDashboard';
import { GjReceivedData } from '../pages/GjReceivedData';
import { GjSendData } from '../pages/GjSendData';
import { GjCreateNewMessage } from '../pages/GjCreateNewMessage';
import { GjComment } from '../pages/GjComment';

export function GjMenuItems(props: { handleDrawerClose: () => void }) {
  const appApi = useContext(AppApiContext);

  const closeDrawerAndChangePage = (fc: React.FC) => {
    props.handleDrawerClose();
    appApi.changePage(fc);
  };

  const onClickDashboard = () => {
    closeDrawerAndChangePage(GjDashboard);
  };

  const onClickCreateNewMessage = () => {
    closeDrawerAndChangePage(GjCreateNewMessage);
  };

  const onClickReceivedData = () => {
    closeDrawerAndChangePage(GjReceivedData);
  };

  const onClickSendData = () => {
    closeDrawerAndChangePage(GjSendData);
  };

  const onClickComment = () => {
    closeDrawerAndChangePage(GjComment);
  };

  return (
    <div>
      <ListItem button onClick={onClickDashboard}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="ダッシュボード" />
      </ListItem>

      <ListItem button onClick={onClickCreateNewMessage}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText primary="新規作成" />
      </ListItem>

      <ListItem button onClick={onClickReceivedData}>
        <ListItemIcon>
          <EmailIcon />
        </ListItemIcon>
        <ListItemText primary="受信履歴" />
      </ListItem>

      <ListItem button onClick={onClickSendData}>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary="送信履歴" />
      </ListItem>

      <ListItem button onClick={onClickComment}>
        <ListItemIcon>
          <CommentIcon />
        </ListItemIcon>
        <ListItemText primary="コメント" />
      </ListItem>
    </div>
  );
}
