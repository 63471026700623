import { Auth } from '@aws-amplify/auth';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import {
    Grid,
    TextField,
    makeStyles,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { AppApiContext } from '../App';
import { GjBackToGjComment } from '../components/GjBackToGjComment';
import { GjBackToDashboard } from '../components/GjBackToDashboard';
import GjTitle from '../components/GjTitle';
import { TbUser, TbAppComment } from '../models';
import { GjComment } from './GjComment';
import { blur } from '@testing-library/user-event/dist/blur';

const useStyles = makeStyles((theme) => ({
    growDiv: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
}));

// 初期フォーム状態。
const initialFormState = {
    id: '',
    userId: '',
    datetime: Number(),
    commentMsg: '',
    completedAt: Number(),
    replyUserId: '',
    replyMsg: '',
    blCompleted: false,
    blAdminsGroup: false,
};

// 返信コメント（編集前）格納用。
// 返信コメントが編集されたかの判定に使用。
const preReplyMsgState = {
    preReplyMsg: '',
};

type tbAppComment = TbAppComment & { userFrom?: TbUser };
type tbUser = TbUser;

export function GjDetailComment(props: string) {
    const [formData, setFormData] = useState(initialFormState);
    const [preReplyMsgData, setPreReplyMsgData] = useState(preReplyMsgState);
    const classes = useStyles();
    const appApi = useContext(AppApiContext);
    // エラー状態
    const [errorMessage, setErrorMessage] = useState('');
    // 返信コメント確認用（エラー判定に使用）
    let preReplyMsg: string | null;
    // コメント表示用リスト
    const [tbAppComment, setTbAppComment] = useState<
        tbAppComment[]
        >([]);
    const [tbUser, setTbUser] = useState<
        tbUser[]
    >([]);
    // ダイアログオープン定義
    const [sendDialogOpen, setSendDialogOpen] = React.useState(false);
    const [variCompleteCommentDialogOpen, setVariCompleteCommentDialogOpen] = React.useState(false);
    const [completeCommentDialogOpen, setCompleteCommentDialogOpen] = React.useState(false);
    const [variDeleteDialogOpen, setVariDeleteDialogOpen] = React.useState(false);
    const [deletedCommentDialogOpen, setDeletedCommentDialogOpen] = React.useState(false);

    // コメントID設定
    let id = "";
    for (var i = 0; i < Object.keys(props).length; i++) {
        id = id + props[i];
    }

    // データ取得。
    async function getData() {
        const authUser = await Auth.currentAuthenticatedUser();
        const tbUser = await DataStore.query(TbUser, authUser.username);
        // コメントステータス取得用変数
        let adminsGroupStatus: boolean;
        let completedStatus: boolean;
        // ログイン中のユーザーがadminsグループに所属しているか確認
        const groupUser = Auth.currentSession()
            .then(user => {
                const { payload } = user.getIdToken()
                if (payload && payload['cognito:groups'] && payload['cognito:groups'].includes('admins')) {
                    console.log(payload['cognito:groups'])
                    // ログイン中のユーザーがadminsグループに所属している場合は、削除ボタンを非表示とする
                    adminsGroupStatus = true;
                }
            })

        if (tbUser) {
            // コメントデータを取得。
            const tbAppComments = await DataStore.query(
                TbAppComment,
                (c) => c.id('eq', id),
                {
                    sort: (s) => s.datetime(SortDirection.DESCENDING),
                }
            );

            // ユーザー名を取得する。Promise.allを使うことで並列にアクセス。
            const tbAppComment = await Promise.all(
                tbAppComments.map(async (tbAppComment) => {
                    const tbUser = await DataStore.query(TbUser, tbAppComment.userId);
                    return { ...tbAppComment, userFrom: tbUser };
                })
            );

            // 取得したデータを反映。
            setTbAppComment(tbAppComment);

            tbAppComment.map((row) => {
                // 返信コメントが未作成（null）の場合は、画面に「null」と表示されないように空文字を設定。
                if (!row.replyMsg) {
                    row.replyMsg = ''
                }
                // 返信コメント（編集前）設定
                setPreReplyMsgData({ preReplyMsg: String(row.replyMsg) })
                // 完了有無編集
                if (row.completedAt) {
                    completedStatus = true
                }
                console.log("完了日：”" + row.completedAt + "”")
                // コメント詳細設定
                setFormData({
                    id: row.id, userId: row.userId, datetime: row.datetime, commentMsg: row.commentMsg, completedAt: Number(row.completedAt),replyUserId: tbUser.id,
                    replyMsg: String(row.replyMsg), blCompleted: completedStatus, blAdminsGroup: adminsGroupStatus
                })

            });

        }
    }

    useEffect(() => {
        getData();
    }, []);

    async function autoBlur() {
        const nowFocus = document.activeElement;
        if (nowFocus) {
            blur(nowFocus);
        }
        
    }

    async function chengeReplyMag(e: { target: { value: any; }; }) {

        setFormData({ ...formData, replyMsg: e.target.value });

    }

    async function handleClick() {
        const authUser = await Auth.currentAuthenticatedUser();
        const tbUser = await DataStore.query(TbUser, authUser.username);

        let id = "";
        for (var i = 0; i < Object.keys(props).length; i++) {
            id = id + props[i];
        }

        // 返信コメントの必須入力チェック
        if (!(formData.replyMsg) || formData.replyMsg === '' || preReplyMsgData.preReplyMsg === formData.replyMsg) {
            setErrorMessage('必須入力項目です。返信コメントを入力する、もしくは返信コメントの編集をしてください。');
            return;
        }
        setErrorMessage('');

        // 返信コメントが未入力のものについては、削除する。
        // 「_deleted」をtureにすることで、物理的に削除はされないが、「削除されたもの」として扱う。
        await DataStore.delete(TbAppComment, formData.id);

        // データ更新（送信）
        // 返信コメントが入力された状態のデータを新しく登録。
        await DataStore.save(
            new TbAppComment({
                userId: formData.userId,
                datetime: formData.datetime,
                commentMsg: formData.commentMsg,
                replyUserId: formData.replyUserId,
                replyMsg: formData.replyMsg,
            }),
        );

        // 送信したよダイアログ表示。
        setSendDialogOpen(true);
    }

    async function handleClickComplete() {
        const authUser = await Auth.currentAuthenticatedUser();
        const tbUser = await DataStore.query(TbUser, authUser.username);

        // コメント完了確認ダイアログ
        setVariCompleteCommentDialogOpen(true);
    }

    async function completeComment() {

        // コメント完了確認ダイアログ非表示
        setVariCompleteCommentDialogOpen(false);

        // コメント完了処理
        // 表示されているコメントを削除し、完了日付が登録されたコメントを再登録する。
        // 「_deleted」をtureにすることで、物理的に削除はされないが、「削除されたもの」として扱う。
        await DataStore.delete(TbAppComment, formData.id);

        // データ更新（送信）
        // 返信コメントが入力された状態のデータを新しく登録。
        await DataStore.save(
            new TbAppComment({
                userId: formData.userId,
                datetime: formData.datetime,
                commentMsg: formData.commentMsg,
                replyUserId: formData.replyUserId,
                replyMsg: formData.replyMsg,
                completedAt: Math.floor(Date.now() / 1000),
            }),
        );

        // コメント完了ダイアログ表示
        setCompleteCommentDialogOpen(true);
    }

    async function handleClickDelete() {
        const authUser = await Auth.currentAuthenticatedUser();

        // コメント削除確認ダイアログ表示
        setVariDeleteDialogOpen(true);
    }

    async function deleteComment() {

        // 取得したデータを反映。
        setTbAppComment(tbAppComment);

        // 「_deleted」をtureにすることで、物理的に削除はされないが、「削除されたもの」として扱う。
        await DataStore.delete(TbAppComment, formData.id);

        // コメント削除確認ダイアログ非表示
        setVariDeleteDialogOpen(false);

        // コメント削除完了ダイアログ表示
        setDeletedCommentDialogOpen(true);
    }

    async function onClose() {

        // コメント完了確認ダイアログ非表示
        setVariCompleteCommentDialogOpen(false);

        // コメント削除確認ダイアログ非表示
        setVariDeleteDialogOpen(false);
    }


  return (
      <React.Fragment>
      <GjTitle>詳細</GjTitle>
          <form>
              {tbAppComment.map((row) => (
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <div className={classes.growDiv}>
                          {row.completedAt ?
                             <TextField
                                  onFocus={autoBlur}
                                  label="完了日"
                                  value={new Date(Number(row.completedAt) * 1000).toLocaleString()}
                             />
                          :
                             <TextField
                                  onFocus={autoBlur}
                                  label="完了日"
                                  value="未完了"
                             />
                           }
                      </div>
                      <div className={classes.growDiv}>
                          <TextField 
                              onFocus={autoBlur}
                              label="日時"
                              value={new Date(row.datetime * 1000).toLocaleString()}
                          />
                      </div>
                      <div className={classes.growDiv}>
                          <TextField 
                              onFocus={autoBlur}
                              label="名前"
                              value={row.userFrom?.name}
                          />
                      </div>
                      <div className={classes.growDiv}>
                          <TextField 
                              multiline
                              onFocus={autoBlur}
                              label="コメント"
                              value={row.commentMsg}
                          />
                      </div>
                      <div className={classes.growDiv}>
                          {row.completedAt ?
                              <TextField
                                  multiline
                                  onFocus={autoBlur}
                                  label="返信コメント"
                                  value={row.replyMsg}
                              />
                          :
                              <TextField
                                  label="返信コメント"
                                  name="replyMsg"
                                  multiline
                                  onChange={chengeReplyMag}
                                  error={errorMessage !== ''}
                                  helperText={errorMessage}
                                  value={formData.replyMsg ? formData.replyMsg : ''}
                              />
                          }
                      </div>
                  </Grid>
              </Grid>
              ))}
              <Grid item xs={12}>
                  <Box textAlign="center">
                      {!formData.blCompleted ?
                          <Button variant="contained" color="primary" onClick={handleClick}>
                              送信
                          </Button>
                      : ''}
                  </Box>
              </Grid>
              <Grid item xs={12}>
                  <Box textAlign="right">
                      {!formData.blCompleted ?
                          <Button variant="contained" color="primary" onClick={handleClickComplete}>
                              完了
                          </Button>
                      : ''}
                      {formData.blAdminsGroup ?
                          <Button variant="contained" color="secondary" onClick={handleClickDelete}>
                              削除
                          </Button>
                      : ''}
                  </Box>
              </Grid>
          </form>
          <GjBackToGjComment />
          <GjBackToDashboard />
          <Dialog open={sendDialogOpen}>
              <DialogTitle>コメント送信完了</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      返信コメントを送信しました。
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      variant="contained"
                      onClick={() => appApi.changePage(GjComment)}
                      color="primary"
                  >
                      OK
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog open={variCompleteCommentDialogOpen}>
              <DialogTitle>コメント完了確認</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      コメントを完了としてもよろしいですか？
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      variant="contained"
                      onClick={completeComment}
                      color="primary"
                  >
                      OK
                  </Button>
                  <Button
                      variant="contained"
                      onClick={onClose}
                      color="primary"
                  >
                      NO
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog open={completeCommentDialogOpen}>
              <DialogTitle>コメント完了</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      コメントを完了しました。
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      variant="contained"
                      onClick={() => appApi.changePage(GjComment)}
                      color="primary"
                  >
                      OK
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog open={variDeleteDialogOpen}>
              <DialogTitle>コメント削除確認</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      コメントを削除してもよろしいですか？
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      variant="contained"
                      onClick={deleteComment}
                      color="primary"
                  >
                      OK
                  </Button>
                  <Button
                      variant="contained"
                      onClick={onClose}
                      color="primary"
                  >
                      NO
                  </Button>
              </DialogActions>
          </Dialog>
          <Dialog open={deletedCommentDialogOpen}>
              <DialogTitle>コメント削除完了</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      コメントを削除しました。
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button
                      variant="contained"
                      onClick={() => appApi.changePage(GjComment)}
                      color="primary"
                  >
                      OK
                  </Button>
              </DialogActions>
          </Dialog>
      </React.Fragment>
  );
}

GjDetailComment.displayName = '詳細';
