import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import GjDashboardSummary from './GjDashboardSummary';
import { GjDashboardReceivedData } from './GjDashboardReceivedData';
import { GjDashboardSendData } from './GjDashboardSendData';
import { TbMessage, TbUser } from '../models';
import { Auth } from '@aws-amplify/auth';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    //height: 260,
  },
}));

export function GjDashboard() {
  const classes = useStyles();
  const [tbMessages, setTbMessages] = useState<TbMessage[]>([]);
  const [tbUser, setTbUser] = useState<TbUser | null>(null);

  async function getAndSetTbUser() {
    const authUser = await Auth.currentAuthenticatedUser();
    const user = await DataStore.query(TbUser, authUser.username);
    if (user) setTbUser(user);
  }

  async function getAndSetTbMessages() {
    const msgs = await DataStore.query(TbMessage, Predicates.ALL, {
      sort: (s) => s.datetime(SortDirection.DESCENDING),
    });
    setTbMessages(msgs);
  }

  useEffect(() => {
    const subscription = DataStore.observe(TbMessage).subscribe((msg) => {
      //console.log('msg', msg);
      getAndSetTbMessages();
    });
    getAndSetTbUser();
    getAndSetTbMessages();

    return () => subscription.unsubscribe();
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <GjDashboardSummary tbMessages={tbMessages} tbUser={tbUser} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <GjDashboardReceivedData tbMessages={tbMessages} tbUser={tbUser} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <GjDashboardSendData tbMessages={tbMessages} tbUser={tbUser} />
        </Paper>
      </Grid>
    </Grid>
  );
}

GjDashboard.displayName = 'ダッシュボード';
