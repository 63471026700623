import { Link } from '@material-ui/core';
import React, { useContext } from 'react';
import { AppApiContext } from '../App';

/**
 * 画面遷移のリンクコンポーネント。
 * @param props.gamen 遷移先コンポーネント。
 * @param props.param 遷移先コンポーネントの引数。
 * @param props.variant 文字の大きさ。
 * @param props.children 子要素。
 * @returns 当コンポーネント。
 */
export const GjLink = (props: {
  gamen: React.FC<any>;
  param?: object;
  variant?: 'h6' | 'body2';
  children: React.ReactNode;
}) => {
  const appApi = useContext(AppApiContext);

  const handleClick = function (event: React.UIEvent) {
    appApi.changePage(props.gamen, props.param ?? {});
  };

  return (
    <Link component="button" variant={props.variant} onClick={handleClick}>
      {props.children}
    </Link>
  );
};
