import { Shubetsu, Category } from './models';

const shubetsuNames = {
  [Shubetsu.GOODJOB]: 'Good Job!',
  [Shubetsu.THANKS]: 'Thanks!',
};

const CategoryNames = {
  [Category.CUSTOMER_ORIENTATION]: '顧客志向',
  [Category.TRY]: '挑戦と改善',
  [Category.FAIR_AND_HONEST]: '公正誠実',
  [Category.RESPECT]: '尊重',
  [Category.TEAM_NID_GROUP]: 'チームNIDグループ',
  [Category.ASSERTIVE]: '積極的',
};

/**
 * 種別の名称を返す。
 * @param shubetsu 種別。
 * @returns 種別名称。
 */
export function shubetsuName(
  shubetsu: Shubetsu | keyof typeof Shubetsu
): string {
  return shubetsuNames[shubetsu];
}

/**
 * カテゴリの名称を返す。
 * @param category カテゴリ。
 * @returns カテゴリ名称。
 */
export function categoryName(
  category: Category | keyof typeof Category
): string {
  return CategoryNames[category];
}
