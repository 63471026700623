import { makeStyles } from '@material-ui/core/styles';
import { GjDashboard } from '../pages/GjDashboard';
import { GjLink } from './GjLink';

const useStyles = makeStyles((theme) => ({
  back: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * ダッシュボードへ戻る。
 * @returns 当コンポーネント。
 */
export function GjBackToDashboard() {
  const classes = useStyles();
  return (
    <div className={classes.back}>
      <GjLink gamen={GjDashboard}>ダッシュボードへ戻る</GjLink>
    </div>
  );
}
