import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

/**
 * NIDのコピーライト表示。
 * @returns 当コンポーネント。
 */
export function GjCopyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://nid.co.jp/">
        NID
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
