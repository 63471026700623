// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const Shubetsu = {
  "GOODJOB": "GOODJOB",
  "THANKS": "THANKS"
};

const Category = {
  "CUSTOMER_ORIENTATION": "CUSTOMER_ORIENTATION",
  "TRY": "TRY",
  "FAIR_AND_HONEST": "FAIR_AND_HONEST",
  "RESPECT": "RESPECT",
  "TEAM_NID_GROUP": "TEAM_NID_GROUP",
  "ASSERTIVE": "ASSERTIVE"
};

const { TbMessage, TbUser, TbDivision, TbAppComment } = initSchema(schema);

export {
  TbMessage,
  TbUser,
  TbDivision,
  TbAppComment,
  Shubetsu,
  Category
};