import { Auth } from '@aws-amplify/auth';
// Kayawake Start Mod ドロップダウンリスト化
//import { DataStore } from '@aws-amplify/datastore';
import { DataStore, Predicates, SortDirection } from '@aws-amplify/datastore';
// Kayawake End Mod

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RowingRounded } from '@material-ui/icons';
// Kayawake Start Mod ドロップダウンリスト化
//import React, { useContext, useState } from 'react';
import React, { useContext, useEffect, useState } from 'react';
// Kayawake End Mod
import { AppApiContext } from '../App';
import { GjBackToDashboard } from '../components/GjBackToDashboard';
import GjTitle from '../components/GjTitle';
import { categoryName, shubetsuName } from '../gjFunctions';
// Kayawake Start Mod 所属取得するためにTbDivisionを追加
//import { Category, Shubetsu, TbMessage, TbUser } from '../models';
import { Category, Shubetsu, TbMessage, TbUser, TbDivision } from '../models';
// Kayawake End Mod
import { GjDashboard } from './GjDashboard';

const useStyles = makeStyles((theme) => ({
  growDiv: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
}));

// 初期フォーム状態。
const initialFormState = {
  toUserId: '',
  message: '',
  category: '',
  shubetsu: '',
};

// Kayawake Start Add ドロップダウンリスト化
type TbUserWithId = TbUser;
type TbDivisionWithId = TbDivision;
// Kayawake End Add

/**
 * 新規メッセージ作成画面。
 */
export function GjCreateNewMessage() {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialFormState);
  // エラー状態。（本当はフォーム状態の一部とすべきとは思うし、やはり、react hook form等のライブラリは有効なのでしょう）
  const [toUserIdErrorMessage, setToUserIdErrorMessage] = useState('');
  const [messageErrorMessage, setMessageErrorMessage] = useState('');
  const [categoryErrorMessage, setCategoryErrorMessage] = useState('');
  const [shubetsuErrorMessage, setShubetsuErrorMessage] = useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const appApi = useContext(AppApiContext);
  
  async function handleClick() {
    const authUser = await Auth.currentAuthenticatedUser();
    const fromUser = await DataStore.query(TbUser, authUser.username);
    const toUser = await DataStore.query(
      TbUser,
      formData.toUserId.trim().toLowerCase()
    );

    // 現ユーザーが取れない。発生しないはずだが、エラーとして抜ける。
    if (fromUser == null) return;

    // 送信先が見つからない。
    if (toUser == null) {
      setToUserIdErrorMessage('送信先が見つかりません。');
      return;
    }
    setToUserIdErrorMessage('');

    // メッセージの必須入力チェック
    if (formData.message === '') {
      setMessageErrorMessage('必須入力項目です。');
      return;
    }
    // メッセージの長さチェック（サロゲートペアを意識してます）
    if (Array.from(formData.message).length > 200) {
      setMessageErrorMessage('メッセージは200字以内で入力下さい。');
      return;
    }
    setMessageErrorMessage('');

    // カテゴリの必須入力チェック
    if (formData.category === '') {
      setCategoryErrorMessage('必須入力項目です。');
      return;
    }
    setCategoryErrorMessage('');

    // 種別の必須入力チェック
    if (formData.shubetsu === '') {
      setShubetsuErrorMessage('必須入力項目です。');
      return;
    }
    setShubetsuErrorMessage('');

    // データ更新（送信）
    await DataStore.save(
      new TbMessage({
        userIdFrom: fromUser.id,
        userIdTo: toUser.id,
        datetime: Math.floor(Date.now() / 1000), // サーバで設定したいところだが、今は良しとする。
        message: formData.message,
        shubetsu: formData.shubetsu as Shubetsu, // 初期値未選択にすると、このような処置が必要みたいです。
        category: formData.category as Category, // 初期値未選択にすると、このような処置が必要みたいです。
        divisionIdFrom: fromUser.divisionId,
        divisionIdTo: toUser.divisionId,
        scoreFrom: 1, // サーバで設定したいところだが、今は良しとする。
        scoreTo: 3, // サーバで設定したいところだが、今は良しとする。
      })
    );

    // Kayawake Start Add メール送信機能追加
    const AWS = require('aws-sdk');
    AWS.config.update({ region: 'ap-northeast-1' });

    // 所属のメーリングリスト取得
    const tbDivisions = await DataStore.query(TbDivision, fromUser.divisionId);
    
    const params = {
      // To
      Destination: {
        ToAddresses: [
          toUser.id,
        ],
        CcAddresses: [
          tbDivisions?.mailingList,
        ],
      },
      // Message
      Message: {
        Body: {
          Text: {
            Data: fromUser.name + ' さんからGoodJobカードが届いています。\n'
            + '確認してください。\n\n'
            + '*--- Message ----------------------------------------*\n'
            + '　【' + formData.shubetsu + '】' + categoryName(formData.category as Category) + '　\n'
            + formData.message + '\n'
            + '*----------------------------------------------------*',
            Charset: 'utf-8'
          },
        },
        Subject: {
          Data: '【Good Job】メッセージが届きました！',
          Charset: 'utf-8',
        },
      },
      // From
      Source: 'n.work.NDD12.SMILE-WG@ml.nid.co.jp',
    };

    AWS.config.update({
      region: 'ap-northeast-1',
      apiVersion: 'latest',
      credentials: {
        accessKeyId: 'AKIAYGBDFHEENOXOW6FX',
        secretAccessKey: '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'
      }
    })

    const ses = new AWS.SES();
    
    try {
      await ses.sendEmail(params).promise()
      console.log("Success to Send an Email")
    } catch (e) {
      console.log(`Failed to Send an Email: ${e}`)
    }
    // Kayawake End Add

    // 送信したよダイアログ表示。
    setDialogOpen(true);
  }

  // Kayawake Start Add ドロップダウンリスト化
  const [TbUserWithIds, setTbUserWithId] = useState<
    TbUserWithId[]
  >([]);

  // データ取得（所属）
  const [TbDivisionWithIds, TbDivisionWithId] = useState<
    TbDivisionWithId[]
  >([]);

  async function getData() {
    const TbDivisionWithIds = await DataStore.query(TbDivision, Predicates.ALL, {
      sort: s => s.id(SortDirection.ASCENDING)
    });
    // 取得したデータを反映
    TbDivisionWithId(TbDivisionWithIds);
  }
  
  useEffect(() => {
    getData();
  }, []);

  // 選択した所属でメールアドレスの絞り込み
  async function onChangeSelectBox(divisionId: any) {
    const TbUserWithIds = await DataStore.query(TbUser, c => c.divisionId("eq", divisionId), {
      sort: s => s.id(SortDirection.ASCENDING)
    });
    // 取得したデータを反映
    setTbUserWithId(TbUserWithIds);
  }
  // Kayawake End Add

  return (
    <>
      <GjTitle>新しいグッジョブカード</GjTitle>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.growDiv}>
              <select
                id="Belongs"
                name="divisionId"
                onChange={(e) => onChangeSelectBox(e.target.value)}
              >
                <option value="" selected>所属を選択してください</option>
                {TbDivisionWithIds.map((row) => (
                  <option value={row.id}>所属：{row.id}</option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.growDiv}>
              <select
                id="MailAddress"
                name="toUserId"
                onChange={(e) =>
                  setFormData({ ...formData, toUserId: e.target.value })
                }
              >
                <option value="" selected>メールアドレスを選択してください</option>
                {TbUserWithIds.map((row) => (
                  <option value={row.id}>氏名：{row.name}　メールアドレス：{row.id}</option>
                ))}
              </select>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.growDiv}>
              <TextField
                label="メッセージ"
                name="message"
                multiline
                rows={5}
                variant="outlined"
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
                error={messageErrorMessage !== ''}
                helperText={messageErrorMessage}
                value={formData.message}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.growDiv}>
              <FormControl error={categoryErrorMessage !== ''}>
                <InputLabel>カテゴリ</InputLabel>
                <Select
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      category: e.target.value as Category,
                    })
                  }
                  value={formData.category}
                >
                  <MenuItem value={Category.CUSTOMER_ORIENTATION}>
                    {categoryName(Category.CUSTOMER_ORIENTATION)}
                  </MenuItem>
                  <MenuItem value={Category.TRY}>
                    {categoryName(Category.TRY)}
                  </MenuItem>
                  <MenuItem value={Category.FAIR_AND_HONEST}>
                    {categoryName(Category.FAIR_AND_HONEST)}
                  </MenuItem>
                  <MenuItem value={Category.RESPECT}>
                    {categoryName(Category.RESPECT)}
                  </MenuItem>
                  <MenuItem value={Category.TEAM_NID_GROUP}>
                    {categoryName(Category.TEAM_NID_GROUP)}
                  </MenuItem>
                  <MenuItem value={Category.ASSERTIVE}>
                    {categoryName(Category.ASSERTIVE)}
                  </MenuItem>
                </Select>
                <FormHelperText>{categoryErrorMessage}</FormHelperText>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.growDiv}>
              <FormControl error={shubetsuErrorMessage !== ''}>
                <InputLabel>種別</InputLabel>
                <Select
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      shubetsu: e.target.value as Shubetsu,
                    })
                  }
                  value={formData.shubetsu}
                >
                  <MenuItem value={Shubetsu.GOODJOB}>
                    {shubetsuName(Shubetsu.GOODJOB)}
                  </MenuItem>
                  <MenuItem value={Shubetsu.THANKS}>
                    {shubetsuName(Shubetsu.THANKS)}
                  </MenuItem>
                </Select>
                <FormHelperText>{shubetsuErrorMessage}</FormHelperText>
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Button variant="contained" color="primary" onClick={handleClick}>
                送信
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
      <GjBackToDashboard />
      <Dialog open={dialogOpen}>
        <DialogTitle>グッジョブカードシステム</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formData.toUserId}
            さんにメッセージを送信しました。ダッシュボードに戻ります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => appApi.changePage(GjDashboard)}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

GjCreateNewMessage.displayName = '新規作成';
