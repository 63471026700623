import React, { useState, useEffect } from 'react';
import { GjDashboard } from './pages/GjDashboard';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { theme } from './theme';

import Amplify, { I18n, Hub } from '@aws-amplify/core';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import awsconfig from '../src/aws-exports';
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
} from '@aws-amplify/ui-react';

import {
  AuthState,
  onAuthUIStateChange,
  Translations,
} from '@aws-amplify/ui-components';
import { Box, Container, CssBaseline } from '@material-ui/core';
import { GjMenu } from './appComponents/GjMenu';
import { GjCopyright } from './appComponents/GjCopyright';
import { Auth } from '@aws-amplify/auth';
import { TbMessage } from './models';

export const AppApiContext = React.createContext({
  /**
   * 画面を切り替える。
   * @param Component 切り替え対象のページコンポーネント。
   * @param props 切り替え対象のページコンポーネントの引数。
   */
  changePage(Component: React.FC<any>, props?: any) {},

  /**
   * ログアウトする。
   */
  logout() {},
});

Amplify.configure(awsconfig);

// 認証画面の日本語化。（AmplifyAuthenticatorで指定することなども可能ではある）
I18n.putVocabulariesForLanguage('ja', {
  // ユーザー認証画面
  [Translations.EMAIL_LABEL]: 'メールアドレス（NIDのもの）',
  [Translations.EMAIL_PLACEHOLDER]: 'tarou.enuaidei@nid.co.jp',
  [Translations.PASSWORD_LABEL]: 'パスワード',
  [Translations.PASSWORD_PLACEHOLDER]: ' ',
  [Translations.SIGN_IN_HEADER_TEXT]: 'グッジョブカードシステム',
  [Translations.FORGOT_PASSWORD_TEXT]: ' ',
  [Translations.RESET_PASSWORD_TEXT]: 'パスワードリセットへ',
  [Translations.NO_ACCOUNT_TEXT]: ' ',
  [Translations.CREATE_ACCOUNT_TEXT]: 'ユーザー登録へ',
  [Translations.SIGN_IN_ACTION]: 'ログイン',

  // ユーザー登録画面
  [Translations.SIGN_UP_HEADER_TEXT]: 'ユーザー登録',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: ' ',
  [Translations.SIGN_IN_TEXT]: 'ユーザー認証へ戻る',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'ユーザー登録',

  // 認証コード確認画面
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: '認証コード確認',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: '認証コード',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: ' ',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: ' ',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: '認証コードを再送',
  [Translations.BACK_TO_SIGN_IN]: 'ユーザー認証へ戻る',
  [Translations.CONFIRM]: '確認',

  // パスワードリセット画面
  [Translations.RESET_YOUR_PASSWORD]: 'パスワードリセット',
  [Translations.SEND_CODE]: '認証コードを送る',

  // パスワードリセット画面（２）
  [Translations.CODE_LABEL]: '認証コード',
  [Translations.CODE_PLACEHOLDER]: ' ',
  [Translations.NEW_PASSWORD_LABEL]: '新しいパスワード',
  [Translations.SUBMIT]: '登録',

  // パスワード変更画面
  [Translations.CHANGE_PASSWORD]: 'パスワード変更',
  [Translations.CHANGE_PASSWORD_ACTION]: '変更',

  // 認証エラー
  [Translations.EMPTY_USERNAME]: 'emailアドレスが空です。',
  [Translations.EMPTY_PASSWORD]: 'パスワードが空です。',
  'User is not confirmed.':
    '認証コードが未登録です。認証コードを入力してください。',
  'User already exists': 'ユーザー登録済みです。',
  'Invalid verification code provided, please try again.':
    '認証コードが誤っています。',
  // ・・・他のエラーは省略・・・

  // ログアウトボタンを使わないのでコメントアウト
  //  // ログアウト
  //  [Translations.SIGN_OUT]: 'ログアウト',
});

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

function App() {
  const [page, setPage] = useState<{ Component: React.FC<any>; props: any }>({
    Component: GjDashboard,
    props: {},
  });
  const [authState, setAuthState] = useState<AuthState>();
  const [dataState, setDataState] = useState('');

  function changePage(Component: React.FC<any>, props = {}) {
    setPage({ Component, props });
  }

  function logout() {
    DataStore.clear()
      .then(() => {
        return Auth.signOut();
      })
      .then(() => {
        setAuthState(AuthState.SignedOut);
        setDataState('');
      });
  }

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      if (nextAuthState !== AuthState.SignedIn) {
        setDataState('');
      }
    });
  }, []);

  useEffect(() => {
    const listener = Hub.listen('datastore', async (hubData) => {
      const { event } = hubData.payload;

      if (event === 'ready') {
        setDataState(event);
      }
    });

    // GSIを利用してデータ同期。
    if (authState === AuthState.SignedIn) {
      DataStore.configure({
        syncExpressions: [
          syncExpression(TbMessage, async () => {
            const authUser = await Auth.currentAuthenticatedUser();
            return (c) =>
              c.or((c) =>
                c
                  .userIdFrom('eq', authUser.username)
                  .userIdTo('eq', authUser.username)
              );
          }),
        ],
      });

      DataStore.start();
    }

    return () => listener();
  }, [authState]); // ログアウト時にDataStore.clearを行うことから、listner設定が消えることへの対処。

  const classes = useStyles();

  return authState === AuthState.SignedIn ? (
    dataState === 'ready' ? (
      <ThemeProvider theme={theme}>
        <AppApiContext.Provider value={{ changePage, logout }}>
          <div className={classes.root}>
            <CssBaseline />
            <GjMenu displayName={page.Component.displayName} />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth="lg" className={classes.container}>
                <page.Component {...page.props} />
                <Box pt={4}>
                  <GjCopyright />
                </Box>
              </Container>
            </main>
          </div>
        </AppApiContext.Provider>
      </ThemeProvider>
    ) : (
      <>
        <div>データロード中...</div>
      </>
    )
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[{ type: 'email' }, { type: 'password' }]}
      />
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
    </AmplifyAuthenticator>
  );
}

export default App;
