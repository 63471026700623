import { makeStyles } from '@material-ui/core/styles';
import { GjComment } from '../pages/GjComment';
import { GjLink } from './GjLink';

const useStyles = makeStyles((theme) => ({
  back: {
        marginTop: theme.spacing(3),
  },
}));

/**
 * コメント画面へ戻る。
 * @returns 当コンポーネント。
 */
export function GjBackToGjComment() {
  const classes = useStyles();
  return (
    <div className={classes.back}>
          <GjLink gamen={GjComment}>コメント画面へ戻る</GjLink>
    </div>
  );
}
